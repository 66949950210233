import { styled } from 'stitches.config'
import { Stack } from 'components/Stack'

export const StepsRoot = styled('div', {
  width: '100%',
  backgroundColor: '$accent-white',
  padding: '$4 0',

  '@bp1': {
    padding: '$4 $2',
  },

  '@bp2': {
    padding: '$6 $4',
  }
})

export const StepsContainer = styled('div', {
  maxWidth: '500px',
  margin: '0 auto',
  backgroundColor: '$secondary-140',
  borderRadius: '0px',
  padding: '$4 $2 $5 $2',
  '@bp1': {
    borderRadius: '$6',
  },
  '@bp2': {
    maxWidth: '1292px',
    padding: '$4',
    paddingTop: '$3',
  }
})

export const StepsList = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$3',
  justifyContent: 'center',
  alignItems: 'center',

  '@bp2': {
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '$10',
  }
})

export const StepItem = styled('div', {
  display: 'flex',
  width: '100%',
  maxWidth: '250px',
  flexDirection: 'column',
  alignItems: 'center',
})

export const StepNumber = styled('div', {
    marginBottom: '0',
    '@bp2': {
        marginBottom: '$1',
        }
})

export const StepContent = styled(Stack, {
  maxWidth: '280px',
  textAlign: 'center',
})

export const ButtonWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '$4',

  '@bp2': {
    marginTop: '$7',
  }
})