import { NutritionClaimRoot, Container, Content } from './NutritionClaim.styles'
import { Text } from 'components/Text'
import useTranslation from 'next-translate/useTranslation'

export const NutritionClaim = () => {
  const { t } = useTranslation()

  return (
    <NutritionClaimRoot>
      <Container>
        <Content>
          <Text 
            size={{ '@initial': 'h3', '@bp2': 'h1' }} 
            color="accent-white" 
            align="center"
            font="display"
          >
            {t('home:nutrition-claim')}
          </Text>
        </Content>
      </Container>
    </NutritionClaimRoot>
  )
}

NutritionClaim.displayName = 'NutritionClaim'