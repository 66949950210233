import { styled } from 'stitches.config'
import { Text } from 'components/Text'

export const FamilyStoryRoot = styled('div', {
  width: '100%',
  backgroundColor: '$accent-white',
  padding: '210px 0 $7 0',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  '@bp2': {
    padding: '$12 $4 $8 $4',
  },
})

export const FamilyStoryContainer = styled('div', {
  position: 'relative',
  maxWidth: '500px',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '$3',

  '@bp2': {
    maxWidth: '1292px',
    flexDirection: 'row',
    paddingTop: '$6',
  },
})

export const ImageColumn = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '4px',
  paddingTop: '$4',

  '@bp2': {
    width: '50%',
    paddingLeft: '$2',
    paddingTop: '0',
  },
})

export const TextColumn = styled('div', {
  width: '100%',

  '@bp2': {
    width: '50%',
  },
})

export const TextContainer = styled('div', {
  width: '100%',
  maxWidth: '600px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '0 $2',
  '@bp2': {
    padding: '0',
  }
})

export const Title = styled(Text, {
  margin: 'auto',
  textAlign: 'center',
  width: '100%',
  maxWidth: '300px',
  '@bp2': {
    maxWidth: 'none',
  },
})

export const TeddyBadge = styled('div', {
  position: 'absolute',
  zIndex: 1,
  top: '-264px',
  width: '198px',
  height: '148px',
  display: 'block',

  '@bp2': {
    display: 'none',
  },

  '@bp3': {
    display: 'block',
    top: '-120px',
    right: '0px',
  },

  '@bp3_1': {
    display: 'block',
    top: '-120px',
    right: '-80px',
  },
})
