import {
  ReviewsRoot,
  ReviewsContainer,
  ReviewCard,
  Title,
  ButtonWrapper,
  SliderContainer,
  ReviewContainer,
} from './Reviews.styles'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { useTranslation } from 'hooks/useTranslation'
import { Button } from 'components/Button'
import Link from 'next/link'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import { sliderSettings } from 'fixtures/slider'
import { Icon } from 'components/Icon'

export const Reviews = () => {
  const { t } = useTranslation()

  const reviews = [
    {
        author: t('home:reviews.3.name'),
        comment: t('home:reviews.3.message'),
      },
    {
      author: t('home:reviews.1.name'),
      comment: t('home:reviews.1.message'),
    },
    {
      author: t('home:reviews.2.name'),
      comment: t('home:reviews.2.message'),
    },
    {
      author: t('home:reviews.4.name'),
      comment: t('home:reviews.4.message'),
    },
  ]

  function Arrow(props: any) {
    const { className, style, onClick, right } = props
    return (
      <Icon
        color="accent-white"
        size="8"
        name={`angle-${right ? 'right' : 'left'}`}
        className={className}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      />
    )
  }

  const sliderSettingsReviews = {
    ...sliderSettings,
    dots: true,
    arrows: true,
    nextArrow: <Arrow right />,
    prevArrow: <Arrow />,
  }

  return (
    <ReviewsRoot>
      <ReviewsContainer>
        <Title color="accent-white" font="display" size="h1">
          {t('home:reviews.title')}
        </Title>
        <SliderContainer>
          <Slider {...sliderSettingsReviews}>
            {reviews.map((review, index) => (
              <ReviewContainer key={index}>
                <ReviewCard >
                  <Text  size="body" transform="uppercase">
                    {review.author}
                  </Text>
                  <Text css={{ marginTop: '12px', '@bp2': { marginTop: '$2' } }} align="left"  size="body">
                    {review.comment}
                  </Text>
                </ReviewCard>
              </ReviewContainer>
            ))}
          </Slider>
        </SliderContainer>
        <ButtonWrapper>
          <Link href="/meal-plan">
            <Button  >{t('home:reviews.cta')}</Button>
          </Link>
        </ButtonWrapper>
      </ReviewsContainer>
    </ReviewsRoot>
  )
}

Reviews.displayName = 'Reviews'
