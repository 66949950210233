import {
  StepsRoot,
  StepsContainer,
  StepsList,
  StepItem,
  StepNumber,
  StepContent,
  ButtonWrapper,
} from './Steps.styles'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { Button } from 'components/Button'
import { useTranslation } from 'hooks/useTranslation'
import Link from 'next/link'

export const Steps = () => {
  const { t } = useTranslation()

  const steps = [
    {
      number: '1',
      title: t('home:mealPlan.step1.title'),
      description: t('home:mealPlan.step1.description'),
    },
    {
      number: '2',
      title: t('home:mealPlan.step2.title'),
      description: t('home:mealPlan.step2.description'),
    },
    {
      number: '3',
      title: t('home:mealPlan.step3.title'),
      description: t('home:mealPlan.step3.description'),
    },
  ]

  return (
    <StepsRoot>
      <StepsContainer>
        <StepsList>
          {steps.map((step, index) => (
            <StepItem key={index}>
              <Stack direction="column" gap="1" alignItems="center">
                <StepNumber>
                  <Text font="display" css={{ fontSize: '$h1' }} color="accent-black">
                    {step.number}.
                  </Text>
                </StepNumber>
                <StepContent direction="column" gap="1">
                  <Text
                    weight="medium"
                    
                    size="body"
                    transform="uppercase"
                    color="accent-black"
                    align="center"
                  >
                    {step.title}
                  </Text>
                  <Text  size="body" color="accent-black" align="center">
                    {step.description}
                  </Text>
                </StepContent>
              </Stack>
            </StepItem>
          ))}
        </StepsList>
      </StepsContainer>
      <ButtonWrapper>
        <Link href="/meal-plan">
          <Button  >{t('home:mealPlan.CTA')}</Button>
        </Link>
      </ButtonWrapper>
    </StepsRoot>
  )
}

Steps.displayName = 'Steps'
