import { HomeRoot } from './Home.styles'
import { Layout } from 'components/Layout'
import { Header } from 'components/Header'
import { useState } from 'react'
import { Hero } from './components/Hero'
import { MealFeatures } from './components/MealFeatures'
import { FullTimeJob } from './components/FullTimeJob'
import { Steps } from './components/Steps'
import { NutritionClaim } from './components/NutritionClaim'
import { PetBenefits } from './components/PetBenefits'
import { Ingredients } from './components/Ingredients'
import { Reviews } from './components/Reviews'
import { FamilyStory } from './components/FamilyStory'
import { CtaFooter } from './components/CtaFooter'
import { Footer } from 'components/Footer'
import { useEffect } from 'react'
import { isElementVisible } from 'utils/dom'

export const Home = () => {
  const header = <Header />

  const [hideCTA, setHideCTA] = useState(true)

  useEffect(() => {
    document.addEventListener('scroll', (e) => {
      if (isElementVisible('a.cta-footer') || isElementVisible('a.cta-hero')) {
        setHideCTA(true)
      } else {
        setHideCTA(false)
      }
    })
  }, [])

  return (
    <HomeRoot>
      <Layout header={header} hideCTA={hideCTA}>
        <Hero />
        <MealFeatures />
        <PetBenefits />
        <FullTimeJob />
        <Steps />
        <Ingredients />
        <NutritionClaim />
        <Reviews />
        <FamilyStory />
        <CtaFooter />
        <Footer />
      </Layout>
    </HomeRoot>
  )
}

Home.displayName = 'Home'
