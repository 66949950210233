import {
  FamilyStoryRoot,
  FamilyStoryContainer,
  TextColumn,
  ImageColumn,
  Title,
  TextContainer,
  TeddyBadge,
} from './FamilyStory.styles'
import { useTranslation } from 'hooks/useTranslation'
import { Text } from 'components/Text'
import alissa from '../../../../../public/assets/home/alissa-zalneraitis.png'
import teddy from '../../../../../public/assets/icons/brand-refresh/teddy-badge.png'
import Image from 'next/image'

export const FamilyStory = () => {
  const { t } = useTranslation()

  return (
    <FamilyStoryRoot>
      <Title font="display" size={{ '@initial': 'h3', '@bp2': 'h1' }}>
        {t('home:family.title')}
      </Title>
      <FamilyStoryContainer>
        <TeddyBadge>
          <Image src={teddy} alt={t('home:family.image')} />
        </TeddyBadge>
        <TextColumn>
          <TextContainer>
            <Text size="body" >
              {t('home:family.subtitle')}
            </Text>
            <Text size="body" >
              {t('home:family.content1')}
            </Text>
            <Text size="body" >
              {t('home:family.content2')}
            </Text>
          </TextContainer>
        </TextColumn>
        <ImageColumn>
          <Image width={506} height={503} src={alissa} alt={t('home:family.image')} />
          <Text
            css={{ paddingTop: '$2' }}
            weight="bold"
            
            size="body"
            transform="uppercase"
          >
            {t('home:family.alissa')}
          </Text>
          <Text size="body">{t('home:family.original-founder')}</Text>
        </ImageColumn>
      </FamilyStoryContainer>
    </FamilyStoryRoot>
  )
}

FamilyStory.displayName = 'FamilyStory'
