import {
  FullTimeJobRoot,
  FullTimeJobContainer,
  DesktopImageColumn,
  MobileImageColumn,
  TextColumn,
  Title,
  Description,
} from './FullTimeJob.styles'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { useTranslation } from 'hooks/useTranslation'
import Image from 'next/image'
import fullTime from '../../../../../public/assets/home/full-time-job.png'
export const FullTimeJob = () => {
  const { t } = useTranslation()

  return (
    <FullTimeJobRoot>
      <FullTimeJobContainer>
        <DesktopImageColumn>
          <Image src={fullTime} alt={t('home:mealPlan.image')} />
        </DesktopImageColumn>
        <MobileImageColumn>
          <Image objectFit='cover' objectPosition='15% 0%' width={500} height={471} src={fullTime} alt={t('home:mealPlan.image')} />
        </MobileImageColumn>
        <TextColumn>
          <Stack gap='2' direction="column" alignItems={{'@initial': 'center', '@bp2': 'flex-start'}}>
            <Title align={{'@initial': 'center', '@bp2': 'left'}} font="display" size={{'@initial': 'h3', '@bp3': 'h1'}}>
              {t('home:mealPlan.title')}
            </Title>
            <Description  align={{'@initial': 'center', '@bp2': 'left'}}  size="body">
              {t('home:mealPlan.subtitle')}
            </Description>
          </Stack>
        </TextColumn>
      </FullTimeJobContainer>
    </FullTimeJobRoot>
  )
}
