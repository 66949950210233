import { CtaFooterRoot, CtaFooterContainer, ButtonWrapper, Title, Subtitle, IconWrapper } from './CtaFooter.styles'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { useTranslation } from 'hooks/useTranslation'
import { Button } from 'components/Button'
import Link from 'next/link'
import { Icon } from 'components/Icon'

export const CtaFooter = () => {
  const { t } = useTranslation()

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <CtaFooterRoot>
      <CtaFooterContainer>
        <IconWrapper>
          <Icon
            onClick={scrollToTop}
            size="7"
            css={{ cursor: 'pointer' }}
            name="arrow-circle-up"
            label={t('home:ending.scrollTop')}
            color="accent-white"
          />
        </IconWrapper>
        <Title font="display" size={{ '@initial': 'h3', '@bp2': 'h1' }} color="accent-white">
          {t('home:ending.title')}
        </Title>
        <Subtitle color="accent-white">{t('home:ending.main')}</Subtitle>
        <ButtonWrapper>
          <Link href="/meal-plan">
            <Button  as="a" className="cta-footer" >{t('home:ending.CTA')}</Button>
          </Link>
        </ButtonWrapper>
        <Stack>
          <Text color="accent-white">{t('home:ending.or')}</Text>
          <Link href="/products">
            <Text css={{ cursor: 'pointer' }} color="accent-white">
              {t('home:ending.secondaryCTA')}
            </Text>
          </Link>
        </Stack>
      </CtaFooterContainer>
    </CtaFooterRoot>
  )
}

CtaFooter.displayName = 'CtaFooter'
