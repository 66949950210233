import Image from 'next/image'
import { Button } from 'components/Button'
import { Text } from 'components/Text'
import { useTranslation } from 'hooks/useTranslation'
import {
  IngredientsRoot,
  IngredientsContainer,
  Title,
  Section,
  BenefitsRow,
  Benefits,
  BenefitText,
  TopImageWrapper,
  Proteins,
  ProteinSelection,
  ProteinRow,
  ProteinTitle,
  BottomImageWrapper,
  Spacer,
  ButtonWrapper,
} from './Ingredients.styles'
import { Icon } from 'components/Icon'
import { IconName } from 'components/Icon/iconNames'
import servingTime from '../../../../../public/assets/home/raw-meal-serving-time.png'
import rawFoodPackages from '../../../../../public/assets/home/raw-food-packages-layout.png'
import Trans from 'next-translate/Trans'
import { Stack } from 'components/Stack'
import Link from 'next/link'

export const Ingredients = () => {
  const { t } = useTranslation()

  const benefits = [
    'home:properties.real-ingredients',
    'home:properties.minimal-processing',
    'home:properties.vet-approved',
  ]

  type proteinType = {
    icon: IconName
    label: string
  }

  const proteins: proteinType[] = [
    { icon: 'cow', label: t('home:proteins.beef') },
    { icon: 'turkey', label: t('home:proteins.turkey') },
    { icon: 'chicken', label: t('home:proteins.chicken') },
    { icon: 'deer', label: t('home:proteins.venison') },
    { icon: 'duck', label: t('home:proteins.duck') },
    { icon: 'lamb', label: t('home:proteins.lamb') },
  ]

  return (
    <IngredientsRoot>
      <IngredientsContainer>
        <Stack justifyContent="center">
          <Title font="display" size={{ '@initial': 'h3', '@bp2': 'h1' }}>
            {t('home:claim.title')}
          </Title>
        </Stack>
        <Section>
          <TopImageWrapper>
            <Image alt={t('home:properties.image')} src={servingTime} width={633} height={438} />
          </TopImageWrapper>
          <Benefits>
            {benefits.map((benefit, index) => (
              <BenefitsRow key={index}>
                <Icon size="4" color="primary-550" name="checkbox-circle-line" />
                <BenefitText  size="body">
                  <Trans i18nKey={benefit} components={{ bold: <Text weight="bold" /> }} />
                </BenefitText>
              </BenefitsRow>
            ))}
          </Benefits>
        </Section>
        <Spacer />
        <Section reverse>
          <Proteins>
            <ProteinTitle  size="body">
              {t('home:proteins.title')}
            </ProteinTitle>
            <ProteinSelection>
              {proteins.map((protein, index) => (
                <ProteinRow
                  key={index}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Icon name={protein.icon} label={protein.label} color="accent-black" size="6" />
                  <Text font="label" size="h5" transform="uppercase">
                    {protein.label}
                  </Text>
                </ProteinRow>
              ))}
            </ProteinSelection>
          </Proteins>
          <BottomImageWrapper>
            <Image width={633} height={438} src={rawFoodPackages} alt={t('home:proteins.image')} />
          </BottomImageWrapper>
        </Section>
        <ButtonWrapper>
          <Link href="/meal-plan">
            <Button  >{t('home:ending.CTA')}</Button>
          </Link>
        </ButtonWrapper>
      </IngredientsContainer>
    </IngredientsRoot>
  )
}

Ingredients.displayName = 'Ingredients'
