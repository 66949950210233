export const sliderSettings: settingsType = {
  dots: true,
  infinite: true,
  speed: 400,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 3000,
  arrows: false,
}

export const sliderSettingsHalfFull: settingsType = {
  dots: false,
  infinite: true,
  speed: 400,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 3000,
  arrows: true,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
      },
    },
  ],
}

type settingsType = {
  dots?: boolean
  infinite: boolean
  speed: number
  slidesToShow: number
  slidesToScroll: number
  autoplay?: boolean
  autoplaySpeed?: number
  arrows?: boolean
  responsive?: Array<{
    breakpoint: number
    settings: {
      slidesToShow: number
      slidesToScroll: number
      dots: boolean
      arrows: boolean
    }
  }>
}
