import { styled } from 'stitches.config'
import { Stack } from 'components/Stack'
import { Box } from 'components/Box'
import { Text } from 'components/Text'
export const MealFeaturesRoot = styled(Box, {
  backgroundColor: '$primary-650',

  '& img': {
    stroke: '20px !important',
  },
})

export const MealFeaturesContainer = styled(Box, {
  width: '100%',
  maxWidth: '1440px',
  margin: 'auto',
  padding: '$5 $4',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$3',

  '@bp2': {
    flexDirection: 'row',
    gap: '$3',
    padding: '$4 $4',
  },

  '@bp3': {
    gap: '$5',
  },

  '@bp4': {
    gap: '$7',
  },
})

export const BalancedText = styled(Text, {
  width: '100%',
  maxWidth: '200px',
  '@bp3': {
    maxWidth: '200px',
  },
})

export const Column = styled(Stack, {
  width: '100%',
  maxWidth: '300px',
  '@bp3': {
    width: 'unset',
    maxWidth: 'none',
  },
})

export const NutritionText = styled(Text, {
  width: '100%',
  maxWidth: '200px',
  '@bp3': {
    maxWidth: '200px',
  },
})

export const UsdaText = styled(Text, {
  width: '100%',
  maxWidth: '200px',
  '@bp3': {
    maxWidth: '200px',
  },
})

export const ShipmentText = styled(Text, {
  width: '100%',
  maxWidth: '250px',
  '@bp3': {
    maxWidth: '250px',
  },
})

export const BalancedImageWrapper = styled('div', {
  width: '60px',
  height: '60px',
  '@bp3': {
    width: '60px',
    height: '60px',
  },
  position: 'relative',
})

export const NutritionImageWrapper = styled('div', {
  width: '60px',
  height: '60px',
  '@bp3': {
    width: '60px',
    height: '60px',
  },
  position: 'relative',
})

export const UsdaImageWrapper = styled('div', {
  width: '60px',
  height: '60px',
  '@bp3': {
    width: '60px',
    height: '60px',
  },
  position: 'relative',
})

export const ShipmentImageWrapper = styled('div', {
  width: '60px',
  height: '60px',
  marginBottom: '5px',
  '@bp3': {
    width: '70px',
    height: '70px',
    marginBottom: '10px',
  },
  position: 'relative',
})

export const ImageColumn = styled('div', {
    height: '60px',
    width: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '@bp3': {
        width: '70px',
        height: '70px',
    }
})
