import {
  PetBenefitsRoot,
  PetBenefitsContainer,
  Title,
  Subtitle,
  BenefitList,
  Column,
  Row,
  ImageContainer,
  BadgeImage,
  Quote,
  QuoteText,
} from './PetBenefits.styles'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { useTranslation } from 'hooks/useTranslation'
import Image from 'next/image'
import hexagons from '../../../../../public/assets/icons/brand-refresh/beehive.svg'
import digestion from '../../../../../public/assets/icons/brand-refresh/digestion.svg'
import energy from '../../../../../public/assets/icons/brand-refresh/energy.svg'
import weight from '../../../../../public/assets/icons/brand-refresh/weight.svg'
import shinyDog from '../../../../../public/assets/icons/brand-refresh/skin.svg'
import mealtime from '../../../../../public/assets/icons/brand-refresh/bowl.svg'
import badge from '../../../../../public/assets/icons/brand-refresh/wfr-usda-raw-badge.svg'

export const PetBenefits = () => {
  const { t } = useTranslation()

  return (
    <PetBenefitsRoot>
      <PetBenefitsContainer>
        <BadgeImage>
            <Image src={badge} alt={t('home:results.badge')}/>
        </BadgeImage>
        <Stack direction="column" alignItems="center" gap={{"@initial": "2", "@bp2": "3"}}>
          <Title font="display" size={{ '@initial': 'h3', '@bp2': 'h1' }}>
            {t('home:results.title')}
          </Title>
          <Subtitle  size="body">
            {t('home:results.text')}
          </Subtitle>
        </Stack>
        <BenefitList>
          <Column>
            <Row>
              <ImageContainer>
                    <Image
                    src={weight}
                    alt={t('home:results.list.weight-img')}
                    width={24}
                    height={24}
                  />
                </ImageContainer>
              <Text>{t('home:results.list.weight')}</Text>
            </Row>
            <Row>
              <ImageContainer>
                <Image height={26} width={26} src={shinyDog} alt={t('home:results.list.coat-img')} />
              </ImageContainer>
              <Text>{t('home:results.list.coat')}</Text>
            </Row>
            <Row>
              <ImageContainer>
                <Image height={26} width={26} src={energy} alt={t('home:results.list.energy-img')} />
              </ImageContainer>
              <Text>{t('home:results.list.energy')}</Text>
            </Row>
          </Column>
          <Column>
            <Row>
              <ImageContainer>
                <Image height={26} width={26} src={hexagons} alt={t('home:results.list.allergies-img')} />
              </ImageContainer>
              <Text>{t('home:results.list.allergies')}</Text>
            </Row>
            <Row>
              <ImageContainer>
                <Image height={26} width={26} src={digestion} alt={t('home:results.list.digestion-img')} />
              </ImageContainer>
              <Text>{t('home:results.list.digestion')}</Text>
            </Row>
            <Row>
              <ImageContainer>
                <Image height={26} width={26} src={mealtime} alt={t('home:results.list.mealtime-img')} />
              </ImageContainer>
              <Text>{t('home:results.list.mealtime')}</Text>
            </Row>
          </Column>
        </BenefitList>
      </PetBenefitsContainer>
      <Quote display={{"@initial": "none", "@bp2": "flex"}} direction="column" alignItems="center">
        <QuoteText color="accent-white" align="center" font="display" size="h1">{t('home:review.text')}</QuoteText>
        <Text color="accent-white" weight="regular" font="label" size="h3">{t('home:review.author')}</Text>
        <Text color="accent-white"  size="body">{t('home:review.origin')}</Text>
      </Quote>
    </PetBenefitsRoot>
  )
}

PetBenefits.displayName = 'PetBenefits'
